import { CSSProperties, useEffect, useState } from "react";
import { cn } from "~/utils";

export type ImageProps = {
  src: string;
  alt: string;
  className?: string;
  style?: CSSProperties;
};

export const Image = ({ src, alt, className, style }: ImageProps) => {
  const [isImageLoading, setImageLoading] = useState(false);

  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
    null,
  );

  useEffect(() => {
    if (imageElement?.complete && !isImageLoading) {
      setImageLoading(false);
    }
  }, [imageElement, isImageLoading]);

  const onImageLoadStart = () => {
    setImageLoading(true);
  };

  const onImageLoad = () => {
    setImageLoading(false);
  };

  return (
    <div className={cn("bg-gray-200", className)} style={style}>
      <img
        ref={setImageElement}
        onLoad={onImageLoad}
        onLoadStartCapture={onImageLoadStart}
        draggable={false}
        className={cn(
          "w-full transition-opacity duration-1000 opacity-0 h-full",
          {
            "opacity-0": isImageLoading,
            "opacity-100": !isImageLoading,
          },
        )}
        src={src}
        alt={alt}
      />
    </div>
  );
};
